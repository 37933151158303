<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Операції по приладах
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <MainModal
            v-if="selectedAppliance.id"
            :main="{ component: 'ApplianceOperation', title: 'Операції із приладами' }"
            :button="{
                    icon: true,
                    color: 'grey lighten-4',
                    buttonIcon: 'mdi-plus',
                    buttonName: '',
                    buttonOutlined: false,
                    buttonIconClass: ''
                  }"
            :item="{
                  appliance_id: selectedAppliance.id,
                  flat_id: flat.id
          }"
        />
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="houseParameterHeaders"
        :items="items"
        hide-default-footer
        class="custom-table custom-table-1"
        @click:row="openItemModal"
        v-if="selectedAppliance.id"
    >
      <template v-slot:item.icon="{ item }">
        <MainModal
            :main="{ component: 'ApplianceOperation', title: 'Операції із приладами' }"
            :button="{ iconActivator: true, class: 'mr-2',
                      color: getApplianceOperationType(item.operation_type, 'color'),
                      buttonIcon: getApplianceOperationType(item.operation_type, 'icon'),
                      iconSize: 26 }"
            :item="{
                      id: item.id,
                      operation_type: item.operation_type,
                      operation_date: item.operation_date,
                      appliance_id: selectedAppliance.id,
                      flat_id: flat.id
                    }"
            :modal="item_dialog_id === item.id"
            @updateItemModal="updateItemModal"
        />
      </template>

      <template v-slot:item.operation_type="{ item }">
        <span>
          {{ getApplianceOperationType(item.operation_type) }}
        </span>
      </template>

      <template v-slot:item.operation_date="{ item }">
        <span>
          {{ item.operation_date | formatDate }}
        </span>
      </template>

    </v-data-table>
  </v-card>
</template>

<script>

import MainModal from "@/components/modal/MainModal";
import {mapGetters} from "vuex";
import {getApplianceOperationType} from "@/utils/icons"
export default {
  name: "HWP_ApplianceOperation",
  components: { MainModal },
  data() {
    return {
      item_dialog_id: 0,
      houseParameterHeaders: [
        { text: '', value: 'icon', width: 26 },
        { text: 'Операція', value: 'operation_type' },
        { text: 'Дата', value: 'operation_date' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'getApplianceOperationJournal',
      flat: 'getFlat',
      selectedAppliance: 'getCurrentSelectedApplianceByFlat',
    }),
  },
  methods: {
    getApplianceOperationType,
    updateItemModal(payload) {
      this.item_dialog_id = 0
      if (payload) {
        if (payload.payload === 'fetch') {
          this.$emit('fetch')
        }
      }
    },
    openItemModal(row) {
      this.item_dialog_id = row.id
    }
  },
}
</script>

<style scoped lang="scss">

</style>